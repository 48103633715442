import request from "@/utils/request";

// 商户设备统计
export function equipment(areaCode, query) {
  return request({
    url: "/bi/merchant/stat/equipment/" + areaCode,
    method: "GET",
    params: query
  });
}

// 商户增量统计
export function incr(areaCode, query) {
  return request({
    url: "/bi/merchant/stat/incr/" + areaCode,
    method: "GET",
    params: query
  });
}

//  监测统计
export function trend(areaCode, query) {
  return request({
    url: "/bi/merchant/stat/output/trend/" + areaCode,
    method: "GET",
    params: query
  });
}

//  商家统计列表
export function List(areaCode, query) {
  return request({
    url: "/bi/merchant/stat/list/" + areaCode,
    method: "GET",
    params: query
  });
}
