<template>
  <div>
    <div class="time-flex">
      日期范围<el-date-picker
        v-model="timeValue"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        size="small"
        value-format="yyyy-MM-dd"
        @change="timeChange"
      >
      </el-date-picker>
      <div
        class="btn-time"
        :class="colorActive == 1 ? '' : 'btn-time-active'"
        @click="monthClick(1)"
      >
        近一月
      </div>
      <div
        class="btn-time"
        :class="colorActive == 3 ? '' : 'btn-time-active'"
        @click="monthClick(3)"
      >
        近三月
      </div>
      <div
        class="btn-time"
        :class="colorActive == 12 ? '' : 'btn-time-active'"
        @click="monthClick(12)"
      >
        近一年
      </div>
    </div>

    <div class="mying-box">
      <div class="mying-box-title">入驻商户</div>
      <div>
        <div class="flex margin-b-10">
          <div class="flex margin-r-10" style="flex:2">
            <div class="mying-box-small margin-r-10" style="width:100%">
              <div v-if="chart_show1">
                <div id="businesses_chart1" class="chart_height"></div>
              </div>
              <div class="empty-text" v-else>暂无数据</div>
              <div class="text-center">类别构成</div>
            </div>
            <div class="mying-box-small" style="width:100%">
              <div v-if="chart_show2">
                <div id="businesses_chart2" class="chart_height"></div>
              </div>
              <div class="empty-text" v-else>暂无数据</div>
              <div class="text-center">区域分布</div>
            </div>
          </div>
          <div class="mying-box-small" style="flex:1">
            <div v-if="chart_show3">
              <div id="merchants-chart4" class="chart_height"></div>
            </div>
            <div class="empty-text" v-else>暂无数据</div>
            <div class="text-center">餐厅增量统计</div>
          </div>
        </div>
        <div class="flex">
          <div class="mying-box-small margin-r-10 flex" style="flex:2">
            <div style="width:100%;">
              <div v-if="chart_show4">
                <div id="equ-chart3" class="chart_height"></div>
              </div>
              <div class="empty-text" v-else>暂无数据</div>
              <div class="text-center">安装设备数量</div>
            </div>
            <div style="width:100%;">
              <div v-if="chart_show5">
                <div id="merchants-chart5" class="chart_height"></div>
              </div>
              <div class="empty-text" v-else>暂无数据</div>
              <div class="text-center">设备使用情况</div>
            </div>
            <div style="width:100%;">
              <div v-if="chart_show6">
                <div id="merchants-chart6" class="chart_height"></div>
              </div>
              <div class="empty-text" v-else>暂无数据</div>
              <div class="text-center">设备使用情况</div>
            </div>
          </div>
          <div class="mying-box-small" style="flex:1">
            <div v-if="chart_show7">
              <div id="merchants-chart7" class="chart_height"></div>
            </div>
            <div class="empty-text" v-else>暂无数据</div>
            <div class="text-center">油/渣监测量统计</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mying-box">
      <!-- <div v-if="tableData.list.length !== 0"> -->
      <div class="form-search flex-v-center">
        <el-form
          :inline="true"
          :model="formSelect"
          class="demo-form-inline"
          size="small"
        >
          <el-form-item label="商户类型">
            <el-select v-model="formSelect.type" clearable placeholder="请选择">
              <el-option
                v-for="item in selectList1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="营业状态">
            <el-select
              v-model="formSelect.business"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in selectList2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备情况">
            <el-select
              v-model="formSelect.bindEquipment"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in equList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="系统状态">
            <el-select
              v-model="formSelect.status"
              clearable
              placeholder="请选择"
            >
              <el-option label="正常" value="1"></el-option>
              <el-option label="关闭" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-input
              v-model="formSelect.seach"
              clearable
              placeholder="请输入商户名称/地址/联系电话"
              style="width:260px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-form>
        <div>导出报表</div>
      </div>
      <div>
        <el-table :data="tableData.list" style="width: 100%">
          <el-table-column prop="shortName" label="商户名称"> </el-table-column>
          <el-table-column prop="bizType" label="商户类型" align="center">
          </el-table-column>
          <el-table-column prop="merchantArea" label="所属区域" min-width="180">
          </el-table-column>
          <el-table-column
            prop="detailAddress"
            label="详细地址"
            min-width="180"
          >
          </el-table-column>
          <el-table-column prop="contactTel" label="联系电话" min-width="100">
          </el-table-column>
          <el-table-column prop="operatState" label="营业状态">
          </el-table-column>
          <el-table-column prop="intoDate" label="入驻日期" min-width="120">
          </el-table-column>
          <el-table-column label="设备情况" align="center">
            <template slot-scope="scope">
              <div
                :style="{
                  color: scope.row.bindEquipment == 1 ? '#83FF58' : '#FF5F57'
                }"
              >
                {{ scope.row.bindEquipment == 1 ? "已安装" : "未安装" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="系统状态" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope" prop="merchantCode">
              <i
                class="el-icon-view look-icon"
                @click="handleEdit(scope.$index, scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex-v-center" style="margin-top: 10px">
          <div></div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="tableData.total"
            :current-page="tableData.currentpage"
            :page-size="tableData.pagesize"
            @current-change="handleCurrentChange"
            prev-text="上一页"
            next-text="下一页"
            hide-on-single-page
          >
          </el-pagination>
        </div>
      </div>
      <!-- </div> -->
      <!-- <div v-else class="empty">
        <img src="@/assets/images/merchants_details/empty.png" alt="" />
        <div>暂无数据</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { incr, equipment, trend, List } from "@/api/those_businesses";
import { merchant, areaOf } from "@/api/index";
export default {
  data() {
    return {
      query: {
        offsetMonth: 1,
        beginDate: "",
        endDate: ""
      },
      areaCode: "",
      timeValue: "",
      colorActive: 1,
      chart_show1: false,
      chart_show2: false,
      chart_show3: false,
      chart_show4: false,
      chart_show5: false,
      chart_show6: false,
      chart_show7: false,
      selectList1: [
        {
          label: "火锅",
          value: 1
        },
        {
          label: "中餐",
          value: 2
        },
        {
          label: "食堂",
          value: 3
        },
        {
          label: "西餐",
          value: 4
        },
        {
          label: "面食",
          value: 5
        },
        {
          label: "小吃",
          value: 6
        },
        {
          label: "料理",
          value: 7
        },
        {
          label: "其他",
          value: 8
        }
      ],
      selectList2: [
        {
          label: "营业",
          value: 1
        },
        {
          label: "歇业",
          value: 2
        },
        {
          label: "关闭",
          value: 3
        },
        {
          label: "转让",
          value: 4
        }
      ],
      equList: [
        {
          label: "已安装",
          value: 1
        },
        {
          label: "未安装",
          value: 2
        }
      ],
      formSelect: {
        type: "",
        business: "",
        status: "",
        seach: "",
        bindEquipment: ""
      },
      tableData: {
        total: 0,
        currentpage: 1,
        pagesize: 5,
        list: []
      }
    };
  },
  mounted() {
    this.monthClick(1);
  },
  methods: {
    // 选择日期范围
    timeChange(e) {
      // console.log(e);
      if (e != null) {
        this.query = {
          beginDate: e[0],
          endDate: e[1]
        };
        this.colorActive = 0;
        this.getmerchant();
        this.getareaOf();
        this.getincr();
        this.getequipment();
        this.gettrend();
        this.getList();
      } else {
        this.colorActive = 1;
        this.monthClick(1);
      }
    },
    monthClick(data) {
      this.colorActive = data;
      this.timeValue = "";
      this.query = {
        offsetMonth: data
      };

      this.getmerchant();
      this.getareaOf();
      this.getincr();
      // 设备情况
      this.getequipment();
      this.gettrend();
      // 获取商户列表信息
      this.getList();
    },
    getmerchant() {
      merchant(this.areaCode, this.query).then(res => {
        // console.log(res);
        if (res.data.length != 0) {
          let data = res.data.map(item => {
            return { value: item.number, name: item.type };
          });
          this.chart_show1 = true;
          this.$nextTick(() => {
            this.Chart1(data);
          });
        } else {
          this.chart_show1 = false;
        }
      });
    },
    getareaOf() {
      areaOf(this.areaCode, this.query).then(res => {
        // console.log(res);
        if (res.data.length != 0) {
          let data = res.data.map(item => {
            return { value: item.number, name: item.area };
          });
          this.chart_show2 = true;
          this.$nextTick(() => {
            this.Chart2(data);
          });
        } else {
          this.chart_show2 = false;
        }
      });
    },
    getincr() {
      incr(this.areaCode, this.query).then(res => {
        // console.log(res);
        if (res.data.length != 0) {
          let xData = [],
            data = [];
          res.data.map(item => {
            xData.push(item.date);
            data.push(item.increment);
          });
          this.chart_show3 = true;
          this.$nextTick(() => {
            this.Chart4(xData, data);
          });
        } else {
          this.chart_show3 = false;
        }
      });
    },
    getequipment() {
      equipment(this.areaCode, this.query).then(res => {
        // console.log(res);

        // 安装设备
        if (res.data.equipmentModel.length != 0) {
          this.chart_show4 = true;
        } else {
          this.chart_show4 = false;
        }
        if (res.data.bizTypeEquipmentStatus.length != 0) {
          this.chart_show5 = true;
        } else {
          this.chart_show5 = false;
        }
        if (res.data.equipmentModelStatus.length != 0) {
          this.chart_show6 = true;
        } else {
          this.chart_show6 = false;
        }
        let data = res.data.equipmentModel.map(item => {
          return { value: item.number, name: item.model };
        });
        // 设备使用1
        let xData1 = [],
          data1 = [],
          data2 = [];
        res.data.bizTypeEquipmentStatus.forEach(item => {
          xData1.push(item.area);
          data1.push(item.onlineNumber);
          data2.push(item.offNumber);
        });
        // 设备使用2
        let xData2 = [],
          data3 = [],
          data4 = [];
        res.data.equipmentModelStatus.forEach(item => {
          xData2.push(item.model);
          data3.push(item.onlineNumber);
          data4.push(item.offNumber);
        });

        this.$nextTick(() => {
          this.Chart3(data);
          this.Chart5(xData1, data1, data2);
          this.Chart6(xData2, data3, data4);
        });
      });
    },
    gettrend() {
      trend(this.areaCode, this.query).then(res => {
        // console.log(res);
        if (res.data.length != 0) {
          let xData = [],
            data1 = [],
            data2 = [];
          res.data.forEach(element => {
            xData.push(element.date);
            data1.push(element.oilWeight);
            data2.push(element.solidResidueWeight);
          });
          this.chart_show7 = true;
          this.$nextTick(() => {
            this.Chart7(xData, data1, data2);
          });
        } else {
          this.chart_show7 = false;
        }
      });
    },
    getList() {
      this.query.pageSize = this.tableData.pagesize;
      this.query.pageNum = this.tableData.currentpage;
      this.query.isAsc = "asc";
      console.log(this.query);
      List(this.areaCode, this.query).then(res => {
        console.log(res);
        this.tableData.list = res.rows;
        this.tableData.total = res.total;
      });
    },
    handleCurrentChange(val) {
      this.tableData.currentpage = val;
      this.getList();
    },
    handleEdit(index, row) {
      // console.log(index, row);
      this.$router.push({
        path: "/merchantsDetails",
        query: { id: row.merchantCode }
      });
    },
    search() {
      this.query.bizType = this.formSelect.type;
      this.query.operatState = this.formSelect.business;
      this.query.sysStatus = this.formSelect.status;
      this.query.condition = this.formSelect.seach;
      this.query.bindEquipment = this.formSelect.bindEquipment;
      this.getList();
    },
    Chart1(data) {
      let businesses_chart1 = this.$echarts.init(
        document.getElementById("businesses_chart1")
      );
      let option = {
        tooltip: {
          trigger: "item"
        },
        // legend: {
        //   orient: "vertical",
        //   left: "5%",
        //   top: "5%",
        //   textStyle: {
        //     color: "#fff"
        //   },
        //   icon: "roundRect"
        // },
        series: [
          {
            name: "类别构成",
            type: "pie",
            radius: ["30%", "70%"],
            center: ["50%", "50%"],
            label: {
              show: true,
              formatter: "{b}: {c} 家" + "\n" + "占比：{d}%",
              textStyle: {
                color: "#fff",
                fontSize: 14,
                lineHeight: 18
              }
            },
            emphasis: {
              label: {
                show: true
              }
            },
            data: data
          }
        ]
      };
      // 绘制图表
      businesses_chart1.setOption(option);

      window.addEventListener("resize", () => {
        businesses_chart1.resize();
      });
    },
    Chart2(data) {
      let businesses_chart2 = this.$echarts.init(
        document.getElementById("businesses_chart2")
      );
      let option = {
        tooltip: {
          trigger: "item"
        },
        // legend: {
        //   orient: "vertical",
        //   left: "5%",
        //   top: "5%",
        //   textStyle: {
        //     color: "#fff"
        //   },
        //   icon: "roundRect"
        // },
        series: [
          {
            name: "区域分布",
            type: "pie",
            radius: ["30%", "70%"],
            center: ["50%", "50%"],
            label: {
              show: true,
              formatter: "{b}: {c} 家" + "\n" + "占比：{d}%",
              textStyle: {
                color: "#fff",
                fontSize: 14,
                lineHeight: 18
              }
            },
            emphasis: {
              label: {
                show: true
              }
            },
            data: data
          }
        ]
      };
      // 绘制图表
      businesses_chart2.setOption(option);

      window.addEventListener("resize", () => {
        businesses_chart2.resize();
      });
    },
    Chart3(data) {
      let equChart3 = this.$echarts.init(document.getElementById("equ-chart3"));
      let option = {
        tooltip: {
          trigger: "item"
        },
        // legend: {
        //   orient: "vertical",
        //   left: "5%",
        //   top: "5%",
        //   textStyle: {
        //     color: "#fff"
        //   },
        //   icon: "roundRect"
        // },
        series: [
          {
            name: "设备数量",
            type: "pie",
            radius: ["30%", "60%"],
            center: ["50%", "50%"],
            label: {
              show: true,
              formatter: "{b}: {c} 台" + "\n" + "占比：{d}%",
              textStyle: {
                color: "#fff",
                fontSize: 12,
                align: "left",
                lineHeight: 14
              }
            },
            emphasis: {
              label: {
                show: true
              }
            },
            data: data
          }
        ]
      };
      // 绘制图表
      equChart3.setOption(option);

      window.addEventListener("resize", () => {
        equChart3.resize();
      });
    },
    Chart4(xData, data) {
      let merchantsChart1 = this.$echarts.init(
        document.getElementById("merchants-chart4")
      );
      let option = {
        grid: {
          bottom: "5%",
          left: "5%",
          right: "5%",
          top: "10%",
          containLabel: true
        },
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
          axisLabel: {
            color: "rgba(48, 216, 252, 1)",
            rotate: "45"
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(48, 216, 252, 0.5)"
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "rgba(48, 216, 252, 1)"
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(48, 216, 252, 0.5)"
            }
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            data: data,
            name: "数量/家",
            type: "line",
            showAllSymbol: true,
            symbol: "circle",
            symbolSize: 5,
            lineStyle: {
              normal: {
                color: "#48B3FF"
              }
            },
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "#48B3FF"
              }
            },

            itemStyle: {
              color: "#FFF",
              borderColor: "#48B3FF",
              borderWidth: 2
            },
            tooltip: {
              show: true
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(195,230,255,0.5)"
                    },
                    {
                      offset: 1,
                      color: "rgba(195,230,255,0.1)"
                    }
                  ],
                  false
                ),
                shadowColor: "rgba(195,230,255,0.1)",
                shadowBlur: 20
              }
            }
          }
        ]
      };
      // 绘制图表
      merchantsChart1.setOption(option);

      window.addEventListener("resize", () => {
        merchantsChart1.resize();
      });
    },
    Chart5(xData1, data1, data2) {
      let merchantsChart1 = this.$echarts.init(
        document.getElementById("merchants-chart5")
      );
      var data = {
        area: xData1,
        legend: ["在线", "离线"],
        data: [data1, data2]
      };
      var colors = [
        "#4C98FB",
        "#73DEBD",
        "#26C978",
        "#8CDF6C",
        "#FBD657",
        "#F56679",
        "#E07BCE",
        "#9D50E0",
        "#634FDA"
      ];
      let option = {
        color: colors,
        legend: {
          top: 10,
          left: 200,
          itemWidth: 10,
          itemHeight: 10,
          // padding: [5, 10],
          textStyle: {
            fontSize: 14,
            color: "#96A4F4",
            padding: [3, 0, 0, 0]
          },
          data: data.legend
        },
        grid: {
          bottom: "5%",
          left: "5%",
          right: "5%",
          top: "16%",
          containLabel: true
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        xAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              color: "rgba(48, 216, 252, 0.2)"
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "rgba(48, 216, 252, 1)"
            }
          },
          data: data.area
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(48, 216, 252, 0.2)"
            }
          },
          axisLabel: {
            textStyle: {
              color: "rgba(48, 216, 252, 1)"
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(48, 216, 252, 0.2)"
            }
          }
        },
        series: []
      };
      for (var i = 0; i < data.legend.length; i++) {
        option.series.push({
          name: data.legend[i],
          type: "bar",
          stack: "总量",
          barWidth: "20",
          label: {
            show: false,
            position: "insideRight"
          },
          data: data.data[i]
        });
      }

      // 绘制图表
      merchantsChart1.setOption(option);

      window.addEventListener("resize", () => {
        merchantsChart1.resize();
      });
    },
    Chart6(xData2, data3, data4) {
      let merchantsChart1 = this.$echarts.init(
        document.getElementById("merchants-chart6")
      );
      var data = {
        area: xData2,
        legend: ["在线", "离线"],
        data: [data3, data4]
      };
      var colors = [
        "#4C98FB",
        "#73DEBD",
        "#26C978",
        "#8CDF6C",
        "#FBD657",
        "#F56679",
        "#E07BCE",
        "#9D50E0",
        "#634FDA"
      ];
      let option = {
        color: colors,
        legend: {
          top: 10,
          left: 200,
          itemWidth: 10,
          itemHeight: 10,
          // padding: [5, 10],
          textStyle: {
            fontSize: 14,
            color: "#96A4F4",
            padding: [3, 0, 0, 0]
          },
          data: data.legend
        },
        grid: {
          bottom: "5%",
          left: "5%",
          right: "5%",
          top: "16%",
          containLabel: true
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        xAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              color: "rgba(48, 216, 252, 0.2)"
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "rgba(48, 216, 252, 1)"
            }
          },
          data: data.area
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(48, 216, 252, 0.2)"
            }
          },
          axisLabel: {
            textStyle: {
              color: "rgba(48, 216, 252, 1)"
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(48, 216, 252, 0.2)"
            }
          }
        },
        series: []
      };
      for (var i = 0; i < data.legend.length; i++) {
        option.series.push({
          name: data.legend[i],
          type: "bar",
          stack: "总量",
          barWidth: "20",
          label: {
            show: false,
            position: "insideRight"
          },
          data: data.data[i]
        });
      }

      // 绘制图表
      merchantsChart1.setOption(option);

      window.addEventListener("resize", () => {
        merchantsChart1.resize();
      });
    },
    Chart7(xData, data1, data2) {
      let merchantsChart1 = this.$echarts.init(
        document.getElementById("merchants-chart7")
      );
      let option = {
        legend: {
          show: true,
          icon: "circle",
          top: "5%",
          textStyle: {
            color: "#fff"
          }
        },
        tooltip: {
          trigger: "axis"
        },
        grid: {
          bottom: "3%",
          left: "5%",
          right: "5%",
          top: "20%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisLine: {
              lineStyle: {
                color: "rgba(48, 216, 252, 0.2)"
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: "rgba(48, 216, 252, 1)"
              }
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(48, 216, 252, 0.2)"
              }
            },
            axisLabel: {
              textStyle: {
                color: "rgba(48, 216, 252, 1)"
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(48, 216, 252, 0.2)"
              }
            }
          }
        ],
        series: [
          {
            name: "油监测",
            type: "line",
            data: data1,
            symbolSize: 6,
            symbol: "circle",
            smooth: true,
            lineStyle: {
              color: "#00ca69"
            },
            itemStyle: {
              normal: {
                color: "#00ca69"
              }
            },
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(0,202,105,0.3)"
                },
                {
                  offset: 1,
                  color: "rgba(0,202,105,0.1)"
                }
              ])
            }
          },
          {
            name: "渣监测",
            type: "line",
            data: data2,
            symbolSize: 6,
            symbol: "circle",
            smooth: true,
            lineStyle: {
              color: "#8b5cff"
            },
            itemStyle: {
              normal: {
                color: "#8b5cff"
              }
            },
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(139,92,255,0.3)"
                },
                {
                  offset: 1,
                  color: "rgba(139,92,255,0.1)"
                }
              ])
            }
          }
        ]
      };
      // 绘制图表
      merchantsChart1.setOption(option);

      window.addEventListener("resize", () => {
        merchantsChart1.resize();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  // align-items: center;
}
.margin-b-10 {
  margin-bottom: 10px;
}
.margin-r-10 {
  margin-right: 10px;
}
.flex-v-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mying-box {
  padding: 20px;
  background: rgba(54, 59, 110, 0.3);
  border: 1px solid #3d4283;
  border-radius: 5px;
  margin-bottom: 20px;
}
.mying-box-title {
  font-size: 18px;
  color: rgba(93, 229, 245, 1);
  margin-bottom: 20px;
}
.mying-box-small {
  width: 100%;
  height: 34vh;
  background: #363b6e;
  border: 1px solid #3d4283;
  border-radius: 5px;
}
.text-center {
  text-align: center;
}
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 20px;
  }
}
.time-flex {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.btn-time {
  background: radial-gradient(#3c427d, #666ca7);
  border: 1px solid #666ca7;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
}
.btn-time:hover {
  color: #ccc;
  border: 1px solid rgba($color: #666ca7, $alpha: 0.8);
}
.btn-time-active {
  background: #191c3c;
  border: 1px solid #323877;
}
.el-range-editor {
  margin: 0 20px;
}
.empty-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
  color: #999;
}
.chart_height {
  width: 100%;
  height: 30vh;
}
.look-icon {
  cursor: pointer;
  &:hover {
    color: #ccc;
  }
}
</style>
